<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import type { Asset, BackgroundColorsPredefined, IntroData } from '~/@types/cms';

    interface InlineNavItem {
        title?: string;
        icon?: string;
        image?: Asset;
        url?: string;
        target?: string;
    }

    declare interface InlineNavItemSliderProps {
        inlineNavItems: InlineNavItem[];
        background?: BackgroundColorsPredefined;
        intro?: IntroData;
    }

    const props = defineProps<InlineNavItemSliderProps>();

    const { setMainSwiper, isBeginning, isEnd, setControls, swipeLeft, swipeRight, onSlideFocus } = useGallery();

    const md = screens('md');
    const lg = screens('lg');

    const itemsCount = computed(() => {
        return props.inlineNavItems?.length ?? 0;
    });

    const calculateSlidesPerView = (upperLimit: number) => ({
        slidesPerView: itemsCount.value < upperLimit ? itemsCount.value : upperLimit,
    });
</script>

<template>
    <mol-section
        :background="background"
        :intro="intro"
        center
        class="org-inline-nav-item-slider overflow-hidden">
        <div class="col-span-2 md:col-span-12">
            <nuxt-error-boundary @error="() => {}">
                <swiper
                    class="relative h-full !overflow-visible"
                    :breakpoints="{
                        0: calculateSlidesPerView(2),
                        [md]: calculateSlidesPerView(3),
                        [lg]: calculateSlidesPerView(6),
                    }"
                    :space-between="16"
                    :centered-slides="false"
                    @swiper="setMainSwiper"
                    @slide-change="setControls">
                    <swiper-slide
                        v-for="(item, index) in inlineNavItems"
                        :key="`${item.title}-${index}`"
                        @focusin="() => onSlideFocus(index)">
                        <nuxt-link
                            :class="[
                                'gtm-click-inline-nav flex h-full flex-col items-center justify-center gap-2 rounded-2xl p-4 text-center lg:min-h-[240px]',
                                {
                                    'transition-colors hover:text-woom-red': item.url,
                                    'bg-woom-white': background === 'black',
                                    'border-grey-mid border bg-woom-white':
                                        background === 'grey' || !background || background === 'light' || background === 'mossgreen',
                                },
                            ]"
                            :data-nav-name="item.title"
                            :to="$helpers.generateLocalePath(item.url)"
                            :target="item.target">
                            <span
                                v-if="item.image"
                                class="flex flex-1 items-center justify-center">
                                <lazy-atm-image
                                    :data="item.image"
                                    sizes="150"
                                    width="150"
                                    image-loading="lazy" />
                            </span>
                            <component
                                v-else
                                :is="item.icon"
                                class="h-20 w-20 flex-1 fill-current" />

                            <span class="min-h-[55px] text-center"> {{ item.title }}</span>
                        </nuxt-link>
                    </swiper-slide>
                    <mol-swiper-navigation
                        :is-beginning="isBeginning"
                        :is-end="isEnd"
                        :swipe-left="swipeLeft"
                        :swipe-right="swipeRight"
                        theme="wider" />
                </swiper>
            </nuxt-error-boundary>
        </div>
    </mol-section>
</template>
